import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 28 27">
    <path
      fill={color}
      d="M1.4,27c-0.3,0-0.7-0.1-1-0.4c-0.5-0.6-0.5-1.4,0-2l12.2-12.2l2,2L2.4,26.6C2.1,26.8,1.8,27,1.4,27z M12.6,13.6L1,25.2
	c-0.2,0.2-0.2,0.5,0,0.7c0.2,0.2,0.5,0.2,0.7,0l11.5-11.5L12.6,13.6z M22.4,14.6c-0.5,0-1-0.2-1.3-0.5l-8.1-8.1
	c-0.3-0.3-0.5-0.8-0.5-1.3c0-0.5,0.2-0.9,0.5-1.3c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,1,0.2,1.3,0.5l8.1,8.1c0.3,0.3,0.5,0.8,0.5,1.3
	c0,0.5-0.2,0.9-0.5,1.3C23.3,14.4,22.9,14.6,22.4,14.6z M14.2,3.6c-0.2,0-0.5,0.1-0.6,0.3c-0.2,0.2-0.3,0.4-0.3,0.7
	c0,0.2,0.1,0.5,0.3,0.6l8.1,8.1c0.3,0.3,1,0.3,1.3,0c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.6l-8.1-8.1
	C14.7,3.7,14.5,3.6,14.2,3.6z M23.9,12c-0.5,0-1-0.2-1.5-0.6l-6.8-6.8c-0.8-0.8-0.8-2-0.1-2.7c0.7-0.7,1.9-0.7,2.7,0.1L25,8.7
	c0.4,0.4,0.6,0.9,0.6,1.4c0,0.5-0.2,0.9-0.5,1.3C24.8,11.8,24.3,12,23.9,12z M16.7,2.2c-0.2,0-0.4,0.1-0.6,0.2
	c-0.3,0.3-0.3,1,0.1,1.4l6.8,6.8c0.4,0.4,1.1,0.5,1.5,0.1c0.1-0.1,0.2-0.4,0.2-0.6c0-0.3-0.1-0.6-0.3-0.8l-6.8-6.8
	C17.4,2.4,17.1,2.2,16.7,2.2C16.8,2.2,16.8,2.2,16.7,2.2z M19.8,16c-0.5,0-1.1-0.2-1.5-0.6l-6.7-6.8c-0.8-0.8-0.8-2-0.1-2.7
	s1.9-0.6,2.7,0.1l6.8,6.8c0.8,0.8,0.8,2,0.1,2.7C20.8,15.8,20.3,16,19.8,16z M12.7,6.2c-0.2,0-0.4,0.1-0.6,0.2
	c-0.3,0.3-0.3,1,0.1,1.4l6.8,6.8c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.5-0.1,0.6-0.2c0.3-0.3,0.3-1-0.1-1.4l-6.7-6.8
	C13.3,6.3,13,6.2,12.7,6.2z M25.2,9.4c-0.5,0-1.1-0.2-1.5-0.6l-5.5-5.5c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.5,0.2-0.9,0.5-1.2
	C18.4,0.1,18.9,0,19.4,0c0.5,0,1.1,0.2,1.5,0.6l5.5,5.5C26.8,6.6,27,7.1,27,7.7c0,0.5-0.2,0.9-0.5,1.2C26.2,9.2,25.7,9.4,25.2,9.4z
	 M19.4,0.8c-0.2,0-0.4,0.1-0.6,0.2c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.3,0.1,0.6,0.3,0.8l5.5,5.5c0.2,0.2,0.5,0.3,0.8,0.3
	c0.2,0,0.4-0.1,0.6-0.2s0.2-0.4,0.2-0.6c0-0.3-0.1-0.6-0.3-0.8l-5.5-5.5C20,1,19.7,0.8,19.4,0.8z M17.2,17.4c-0.5,0-1.1-0.2-1.5-0.6
	l-5.5-5.5c-0.4-0.4-0.6-0.9-0.6-1.5c0-0.5,0.2-0.9,0.5-1.2C10.4,8.1,10.8,8,11.3,8c0.5,0,1.1,0.2,1.5,0.6l5.5,5.5
	c0.4,0.4,0.6,0.9,0.6,1.5c0,0.5-0.2,0.9-0.5,1.2C18.2,17.2,17.7,17.4,17.2,17.4z M11.4,8.8c-0.2,0-0.4,0.1-0.6,0.2
	c-0.1,0.1-0.2,0.4-0.2,0.6c0,0.3,0.1,0.6,0.3,0.8l5.5,5.5c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.4-0.1,0.6-0.2c0.1-0.1,0.2-0.4,0.2-0.6
	c0-0.3-0.1-0.6-0.3-0.8l-5.5-5.5C12,9,11.7,8.8,11.4,8.8z"
    />
  </svg>
);
